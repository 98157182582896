const baseUrl = () => 'https://api.codat.io'
export const routes = {
    baseUrl,
    profile: ({ codatCompanyId }: { codatCompanyId: string }) =>
        `https://app.codat.io/companies/${codatCompanyId}`,
    connections: ({ codatCompanyId }: { codatCompanyId: string }) =>
        `https://app.codat.io/companies/${codatCompanyId}/connections`,

    //
    inflowOutflowActivity: (args: { companyId: string }) => {
        return `/data/companies/${args.companyId}/assess/bankingMetrics/inflowOutflowActivity`
    },
    company(companyId: string) {
        return `/companies/${companyId}`
    },
    bankingConnectionDetails(companyId: string, connectionId: string) {
        return `/companies/${companyId}/connections/${connectionId}/data/banking-accounts`
    },
    connectionDetails(companyId: string, connectionId: string) {
        return `/companies/${companyId}/connections/${connectionId}`
    },
    bankingAccountBalances(companyId: string, connectionId: string) {
        return `/companies/${companyId}/connections/${connectionId}/data/banking-accountBalances`
    },
    refreshAllData(companyId: string) {
        return `/companies/${companyId}/data/all`
    },
}
