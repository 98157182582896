import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    tabsAnatomy.keys,
)

const baseStyle = definePartsStyle({
    tablist: defineStyle({}),
})

const variants = {
    primary: definePartsStyle(({ theme }) => {
        const colors = theme.colors

        return {
            tab: {
                textTransform: 'uppercase',
                color: colors.captions.light,
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '20px',
                px: 0,
                py: 0,
                borderBottomWidth: '2px',
                borderBottomColor: 'transparent',

                _selected: {
                    borderColor: colors.green.default,
                    color: colors.green.default,
                },
                _hover: {
                    color: colors.green.default,
                },
                ':not(:last-child)': {
                    mr: '20px',
                },
            },
            tabpanel: {
                p: 0,
            },
        }
    }),
}

export const Tabs = defineMultiStyleConfig({
    baseStyle,
    variants,
    defaultProps: {
        variant: 'primary',
    },
})
